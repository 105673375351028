import React from 'react'
import { Link } from 'react-router-dom'

const Button = ({ fontColor, borderColor, children, link }) => {
    return (
        <Link to={link} className={`${fontColor} ${borderColor} rounded-2xl py-3 px-4 w-max cursor-pointer`}>
            {children}
        </Link>
    )
}

export default Button
