import React from 'react'

const LogoSection = () => {
    return (
        <div className='flex justify-center py-5'>
            <img src='/assets/logo.webp' alt='Logo' />
        </div>
    )
}

export default LogoSection
