import React from 'react';
import { Navigate, useRoutes } from "react-router-dom";

import HomePage from './pages/HomePage/HomePage';
import PortfolioPage from './pages/PortfolioPage/PortfolioPage';
import ContactUsPage from './pages/ContactUsPage/ContactUsPage';
import AboutUsPage from './pages/AboutUsPage/AboutUsPage';

export default function Router() {
  const routes = useRoutes([
    {
      path: "/",
      element: <HomePage />,
    },
    {
      path: "/portfolio",
      element: <PortfolioPage />,
    },
    {
      path: "/about-us",
      element: <AboutUsPage />,
    },
    {
      path: "/contact-us",
      element: <ContactUsPage />,
    },
    {
      path: "*",
      element: <Navigate to='/404' replace />,
    },
  ]);

  return routes;
}
