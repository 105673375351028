import BrownSection from '../../sections/ContactUs/BrownSection.js/BrownSection'
import AboutMe from '../../sections/ContactUs/AboutMe/AboutMe'
import AboutWork from '../../sections/ContactUs/AboutWork/AboutWork'
import React from 'react'
import LogoSection from '../../sections/LogoSection/LogoSection'

const AboutUsPage = () => {
  return (
    <div className='w-full max-w-[1440px]'>
      <AboutMe isHome={true} />
      <BrownSection content={"My work can be found in the fashion, homewares, lifestyle and fabric industries. I love to work with brands that align with my values and I’m doing the work I truely love and wake up everyday excited to create."} fontColor={"text-white"} />
      <AboutWork />
      <LogoSection />
    </div>
  )
}

export default AboutUsPage
