import React from 'react'
import StyledH2Heading from '../../../common/styledH2Heading/StyledH2Heading';
import Portfolio from '../../../components/Portfolio/Portfolio'

const PortfolioSection = () => {
    return (
        <div className='h-full relative bg-brown pt-[1%] pb-[4%]'>
            <div className='absolute hidden md:block -z-2 md:-right-[20%] -top-[30%] right-0 lg:-right-[7%]'>
                <img src='/assets/petal-down.webp' alt='Background Petal' />
            </div>
            <div className='flex flex-col items-center'>
                <div><StyledH2Heading fontColor={"text-white"}>Portfolio</StyledH2Heading></div>
                <Portfolio />
            </div>
            <div className='absolute lg:-bottom-[30%] md:-left-[20%] -bottom-[10%] -left-[50%] lg:-left-[9%]'>
                <img src='/assets/petal-up.webp' alt='Background Petal' />
            </div>
        </div>
    )
}

export default PortfolioSection
