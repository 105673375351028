import React from "react";
import Marquee from "react-fast-marquee";
import { content } from "../../data";

const MarqueeComponent = () => {
  return (
    <div>
      <Marquee speed={"30"} pauseOnHover>
        {content.homepage.Clients.map((item, index) => (
          <div key={index} className="py-5 mr-5">
            <img className="duration-500 hover:scale-110" src={item.logoUrl} alt="#" />
          </div>
        ))}
      </Marquee>
    </div>
  );
};

export default MarqueeComponent;
