import React from 'react'
import HomePortfolio from '../../sections/Portfolio/homePortfolio/HomePortfolio'
import FashionPortfolio from '../../sections/Portfolio/fashionPortfolio/FashionPortfolio'

const PortfolioPage = () => {
  return (
    <div className='w-full max-w-[1440px] overflow-x-hidden'>
      <style jsx>{`
        ::-webkit-scrollbar {
          width: 0 !important;
        }
      `}</style>
      <FashionPortfolio />
      <HomePortfolio />
    </div>
  )
}

export default PortfolioPage
