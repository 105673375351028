import React from 'react'
import MarqueeLandingPage from '../../../components/marqueeLandingPage/MarqueeLandingPage'

const LandingSection = () => {
    return (
        <>
            <div className='overflow-x-hidden relative h-full py-[70%] md:py-[30%] lg:py-[20%]' style={{
                background: `url("/assets/lPageBg.webp")`,
                backgroundRepeat: "no-repeat",
                backgroundSize:"cover"
            }}>
                <div className='absolute top-[45%]'>
                    <MarqueeLandingPage />
                </div>
            </div>
            <div className='bg-brown'><img src='/assets/bg-border.webp' alt='Border' /></div>
        </>
    )
}

export default LandingSection
