import React, { useState } from 'react'
import StyledH2Heading from '../../../common/styledH2Heading/StyledH2Heading';
import StyledSmText from '../../../common/styledSmText/StyledSmText';
import { Link } from 'react-router-dom';
import { contactConfig } from '../../../data';
import * as emailjs from "emailjs-com";

const FormSection = () => {

    const [formData, setFormdata] = useState({
        email: "",
        firstName: "",
        lastName: "",
        message: "",
        businessName: "",
        socialNetwork: "",
        services: "",
        loading: false,
        show: false,
        alertmessage: "",
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormdata({ loading: true });
        // Scroll to the top of the page
        const isMobile = window.innerWidth <= 768;
        const options = isMobile ? { top: 0, behavior: "auto" } : { top: 0, behavior: "smooth" };
        window.scrollTo(options);

        const templateParams = {
            from_name: formData.email,
            user_name: formData.firstName + " " + formData.lastName,
            to_name: contactConfig.YOUR_EMAIL,
            message: formData.message,
            businessName: formData.businessName,
            socialNetwork: formData.socialNetwork,
            services: formData.services,
        };

        emailjs
            .send(
                contactConfig.YOUR_SERVICE_ID,
                contactConfig.YOUR_TEMPLATE_ID,
                templateParams,
                contactConfig.YOUR_USER_ID
            )
            .then(
                (result) => {
                    setFormdata({
                        loading: false,
                        alertmessage: "SUCCESS! Your enquiry has been sent.",
                        variant: "success",
                        show: true,
                    });
                },
                (error) => {
                    console.log(error.text);
                    setFormdata({
                        alertmessage: `Failed to send!,${error.text}`,
                        variant: "danger",
                        show: true,
                    });
                }
            );
    };

    const handleChange = (e) => {
        setFormdata({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <div style={{
            background: `url("/assets/bg-form.png")`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right",
            backgroundSize: "contain"
        }} className='h-full pb-10'>
            <div className='text-center'><StyledH2Heading fontColor={"text-pink"}>Contact Me</StyledH2Heading></div>
            <div className='flex flex-col items-center'>
                <div className='w-[90%]'>
                    <form onSubmit={handleSubmit} className='flex flex-col gap-4 lg:w-[50%] bg-yellow bg-opacity-[0.2] lg:bg-opacity-0 lg:blur-none blur-[0.5px] lg:bg-transparent'>
                        <div onClose={() => setFormdata({ show: false })}
                            dismissible className={`rounded-0 text-center text-white ${formData.show ? "bg-brown d-block" : "d-none"
                                }`}>
                            <p className="my-5">{formData.alertmessage}</p>
                        </div>
                        <div><StyledSmText fontColor={"text-dark-blue"}>Reach out and lets have a chat about how we can create some magic together | <Link className='underline hover:text-pink' to="mailto:aimancreates.official@gmail.com">aimancreates.official@gmail.com</Link></StyledSmText></div>
                        <div className='flex flex-col gap-1'>
                            <div><StyledSmText fontColor={"text-black"}>Name <span className='text-dark-gray'>(required)</span></StyledSmText></div>
                            <div className='flex gap-3'>
                                <div className='flex flex-col w-1/2 gap-1'>
                                    <div><StyledSmText fontColor={"text-black"}>First name</StyledSmText></div>
                                    <div>
                                        <input type='text' id="firstName"
                                            name="firstName"
                                            value={formData.firstName || ""}
                                            required
                                            onChange={handleChange} className='h-[40px] w-full p-2' />
                                    </div>
                                </div>
                                <div className='flex flex-col w-1/2 gap-1'>
                                    <div><StyledSmText fontColor={"text-black"}>Last name</StyledSmText></div>
                                    <div>
                                        <input type='text' id="lastName"
                                            name="lastName"
                                            value={formData.lastName || ""}
                                            required
                                            onChange={handleChange} className='h-[40px] w-full p-2' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-1'>
                            <div><StyledSmText fontColor={"text-black"}>Email <span className='text-dark-gray'>(required)</span></StyledSmText></div>
                            <div><input id="email"
                                name="email"
                                type="email"
                                value={formData.email || ""}
                                required
                                onChange={handleChange} className='h-[40px] w-full p-2' /></div>
                        </div>
                        <div className='flex flex-col gap-1'>
                            <div><StyledSmText fontColor={"text-black"}>Brand/Business name <span className='text-dark-gray'>(required)</span></StyledSmText></div>
                            <div><input
                                type='text'
                                id="businessName"
                                name="businessName"
                                value={formData.businessName || ""}
                                required
                                onChange={handleChange}
                                className='h-[40px] w-full p-2'
                            /></div>
                        </div>
                        <div className='flex flex-col gap-1'>
                            <div><StyledSmText fontColor={"text-black"}>How did you find about me? <span className='text-dark-gray'>(required)</span></StyledSmText></div>
                            <div>
                                <select
                                    id="socialNetwork"
                                    name="socialNetwork"
                                    className='h-[40px] w-full'
                                    value={formData.socialNetwork || ""}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled>Select an option</option>
                                    <option>Linkedin</option>
                                    <option>Instagram</option>
                                    <option>Facebook</option>
                                    <option>Other</option>
                                </select>

                            </div>
                        </div>
                        <div className='flex flex-col gap-1'>
                            <div><StyledSmText fontColor={"text-black"}>What services are you interested in? <span className='text-dark-gray'>(required)</span></StyledSmText></div>
                            <div className='flex flex-col gap-2'>
                                <div className='flex'>
                                    <div className='flex items-center'>
                                        <input
                                            class="relative h-[1.125rem] w-[1.125rem] "
                                            type="checkbox"
                                            id="onlinePrintShop"
                                            name="services"
                                            value="Online Print Shop"
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div>
                                        <label
                                            class="pl-[0.5rem] text-sm hover:cursor-pointer"
                                            for="onlinePrintShop">
                                            Online Print Shop
                                        </label>
                                    </div>
                                </div>
                                <div className='flex'>
                                    <div className='flex items-center'>
                                        <input
                                            class="relative h-[1.125rem] w-[1.125rem] "
                                            type="checkbox"
                                            name="services"
                                            onChange={handleChange}
                                            value="Custom Print Design"
                                            id="customPrintDesign" />
                                    </div>
                                    <div>
                                        <label
                                            class="pl-[0.5rem] text-sm hover:cursor-pointer"
                                            for="customPrintDesign">
                                            Custom Print Design
                                        </label>
                                    </div>
                                </div>
                                <div className='flex'>
                                    <div className='flex items-center'>
                                        <input
                                            class="relative h-[1.125rem] w-[1.125rem] "
                                            type="checkbox"
                                            value="Licensing"
                                            id="licensing"
                                            name="services"
                                            onChange={handleChange} />
                                    </div>
                                    <div>
                                        <label
                                            class="pl-[0.5rem] text-sm hover:cursor-pointer"
                                            for="licensing">
                                            Licensing
                                        </label>
                                    </div>
                                </div>
                                <div className='flex'>
                                    <div className='flex items-center'>
                                        <input
                                            class="relative h-[1.125rem] w-[1.125rem] "
                                            type="checkbox"
                                            id="other"
                                            name="services"
                                            value="Other"
                                            onChange={handleChange} />
                                    </div>
                                    <div>
                                        <label
                                            class="pl-[0.5rem] text-sm hover:cursor-pointer"
                                            for="other">
                                            Other
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-1'>
                            <div><StyledSmText fontColor={"text-black"}>Messages <span className='text-dark-gray'>(required)</span></StyledSmText></div>
                            <div>
                                <textarea className='w-full p-2' id="message"
                                    name="message"
                                    rows="5"
                                    value={formData.message}
                                    onChange={handleChange}
                                    required />
                            </div>
                        </div>
                        <div className='flex justify-center lg:justify-start'>
                            <button type="submit" className='px-4 py-3 duration-500 border cursor-pointer rounded-2xl w-max text-pink hover:bg-pink hover:text-white hover:border-pink border-pink'>{formData.loading ? "Submitting..." : "Submit Now"}</button>
                        </div>
                    </form>
                </div>
            </div>
            <div className={formData.loading ? "loading-bar" : ""}></div>
        </div>
    )
}

export default FormSection
