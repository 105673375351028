import LandingSection from '../../sections/Home/LandingSection/LandingSection'
import AboutMe from '../../sections/ContactUs/AboutMe/AboutMe'
import ServicesSection from '../../sections/Home/ServicesSection/ServicesSection'
import React from 'react'
import LogoSection from '../../sections/LogoSection/LogoSection'
import CollaborationSection from '../../sections/Home/CollaborationSection/CollaborationSection'
import AboutWork from '../../sections/ContactUs/AboutWork/AboutWork'
import ClientsSection from '../../sections/Home/ClientsSection/ClientsSection'
import PortfolioSection from '../../sections/Home/PortfolioSection/PortfolioSection'
import TestimonialsSection from '../../sections/Home/TestimonialsSection/TestimonialsSection'
// import InstagramSection from '../../sections/Home/InstagramSection/InstagramSection'

const HomePage = () => {
  return (
    <div className='w-full max-w-[1440px] overflow-x-hidden'>
      <div className='bg-brown'>
        <LandingSection />
      </div>
      <AboutMe />
      <ServicesSection />
      <PortfolioSection />
      <AboutWork />
      <ClientsSection />
      <TestimonialsSection />
      {/* <InstagramSection /> */}
      <CollaborationSection />
      <LogoSection />
    </div>
  )
}

export default HomePage
