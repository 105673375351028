import StyledXlText from '../../../common/styledXlText/StyledXlText'
import React from 'react'

const BrownSection = ({ content, fontColor }) => {
    return (
        <div className='flex justify-center py-10 bg-brown'>
            <div className='w-[90%] text-center'>
                <StyledXlText fontColor={fontColor}>{content}</StyledXlText>
            </div>
        </div>
    )
}

export default BrownSection
