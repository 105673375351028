import StyledLgText from '../../../common/styledLgText/StyledLgText'
import StyledH2Heading from '../../../common/styledH2Heading/StyledH2Heading'
import React from 'react'
import { Link } from 'react-router-dom'

const CollaborationSection = () => {
    return (
        <div className='h-full py-5' style={{
            background: `url('/assets/bg-collaboration.webp')`,
            backgroundRepeat:"repeat-y"
        }}>
            <div className='flex flex-col items-center gap-5'>
                <div className='lg:w-[90%] text-center px-5 lg:px-0 lg:rounded-xl leading-none flex justify-center bg-yellow'>
                    <StyledH2Heading fontColor={"text-dark-blue"}>Have Some Questions In Mind?</StyledH2Heading>
                </div>
                <div className='lg:w-[40%] text-center flex flex-col lg:rounded-xl px-5 lg:px-10 py-5 lg:py-10 bg-yellow gap-5'>
                    <div><StyledLgText fontColor={"text-black"}>If you like what you see and would like to collaborate together, Email me directly and I’ll get back to you within 24 hours:</StyledLgText></div>
                    <div><StyledLgText fontColor={"text-dark-blue underline hover:text-pink"}><Link to="mailto:aimancreates.official@gmail.com">aimancreates.official@gmail.com</Link></StyledLgText></div>
                    <div><StyledLgText fontColor={"text-black"}>Thank you and I look forward to hearing more about your project!</StyledLgText></div>
                </div>
            </div>
        </div>
    )
}

export default CollaborationSection
