import React from 'react'
import { content } from "../../data";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const Portfolio = () => {

    const responsive = {
        0: { items: 1 },
        768: { items: 2 },
        1024: { items: 3 },
        1440: { items: 4 },
    };

    const renderPrevButton = ({ isDisabled }) => (
        <button
            disabled={isDisabled}
            style={{
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                top: "45%",
                left: "-3%",
                width: "30px",
                height: "30px",
                background: "#fff",
                border: "1px solid #3E3E3E99",
                borderRadius: "50%",
                color: "#000",
                fontSize: "18px",
            }
            }>
            <img
                src="/assets/backarrowlight.svg"
                alt="back-arrow"
            />
        </button>
    );

    const renderNextButton = ({ isDisabled }) => (
        <button
            disabled={isDisabled}
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: "45%",
                right: "-3%",
                width: "30px",
                height: "30px",
                background: "#fff",
                border: "1px solid #3E3E3E99",
                borderRadius: "50%",
                color: "#000",
                fontSize: "18px",
            }}
        >
            <img
                filter="hue-rotate(120deg)"
                style={{ fill: "red !important" }}
                src="/assets/nextarrowlight.svg"
                alt="next-arrow"
            />
        </button>
    );
    return (
        <div className='flex w-[90%] gap-3 z-20'>
            <AliceCarousel
                disableDotsControls={"true"}
                renderPrevButton={renderPrevButton}
                renderNextButton={renderNextButton}
                mouseTracking
                items={content.portfolio.Fashion?.map((item, index) => (
                    <div key={index} className='mx-6 lg:mx-5'>
                        <div className='min-w-[245px] min-h-[345px] w-full h-full p-1 bg-white'>
                            <img
                                src={item.imageUrl}
                                alt={`Fashion Portfolio#${index + 1}`}
                                className='object-cover w-full h-full'
                            />
                        </div>
                    </div>
                ))}
                responsive={responsive}
                controlsStrategy="alternate"
                autoPlay="true"
                autoPlayInterval="5000"
                autoPlayStrategy="all"
                infinite="true" />
        </div>
    )
}

export default Portfolio
