import React from 'react'
import CollaborationSection from '../../sections/Home/CollaborationSection/CollaborationSection'
import FormSection from '../../sections/ContactUs/FormSection/FormSection'
import LogoSection from '../../sections/LogoSection/LogoSection'

const ContactUsPage = () => {
  return (
    <div className='w-full max-w-[1440px]'>
      <FormSection />
      <CollaborationSection />
      <LogoSection />
    </div>
  )
}

export default ContactUsPage
