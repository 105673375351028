import React from 'react'
import Testimonials from '../../../components/Testimonials/Testimonials'

const TestimonialsSection = () => {
    return (
        <div style={{
            background: `url("/assets/bg-collaboration.webp")`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: "cover"
        }} className='h-full py-[8%]'>
            <div className='flex justify-center'>
                <Testimonials />
            </div>
        </div>
    )
}

export default TestimonialsSection
