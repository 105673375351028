import React from "react";
const Loader = () => {
    return (
        <div
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.7)", // Adjust opacity here
                zIndex: 9999, // Adjust z-index as needed
            }}
        >
            <div className="py-[4rem] flex fixed items-center justify-center">
                <img src="/assets/loader.gif" alt="Loader" width="200" height="200" />
                    
            </div>
        </div>
    );
};

export default Loader;
