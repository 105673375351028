import React from 'react'
import { content } from '../../../data'
import StyledH2Heading from '../../../common/styledH2Heading/StyledH2Heading'
import StyledH5Heading from '../../../common/styledH5Heading/StyledH5Heading'
import StyledH6Heading from '../../../common/styledH6Heading/StyledH6Heading'
import StyledLgText from '../../../common/styledLgText/StyledLgText'
import Button from '../../../components/button/Button'

const AboutWork = () => {
  return (
    <div className='relative flex flex-col items-center'>
      <div className='flex justify-center'>
        <StyledH2Heading fontColor={"text-dark-blue"}>About Work</StyledH2Heading>
      </div>
      <div className='absolute bottom-0 w-full'><img src='/assets/border.webp' alt='Border' /></div>
      <div className='w-[90%] lg:flex lg:flex-row flex flex-col'>
        <div className='lg:w-[50%] flex flex-col gap-5'>
          <div className='hidden lg:flex'><StyledH5Heading fontColor={"text-dark-blue font-hind"}>About Work</StyledH5Heading></div>
          <div><StyledLgText fontColor={"text-black"}>If you struggling to create and translate your ideas in surface pattern design and
            want to use the right textile design on the right product then I can probably help.</StyledLgText></div>
          <div><StyledH6Heading fontColor={"text-black font-hind"}>Available Services Include:</StyledH6Heading></div>
          <div className='flex flex-col gap-3 lg:gap-5'>
            {content.aboutUs.AboutWork.Services.map((item, index) => (
              <div key={index} className='flex items-center gap-5'>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                    <circle cx="6.5" cy="6.5" r="6.5" fill="#A5714F" />
                  </svg>
                </div>
                <div><StyledLgText fontColor={"text-black"}>{item.title}</StyledLgText></div>
              </div>
            ))}
          </div>
          <div><StyledH6Heading fontColor={"text-black font-hind"}>For more details, please contact me.</StyledH6Heading></div>
          <div className='flex justify-start'>
            <Button link={"/contact-us"} fontColor={"text-black hover:bg-black hover:text-white duration-500 z-10"} borderColor={"border border-black"}>Get in Touch</Button>
          </div>
        </div>
        <div className='lg:w-[50%] flex justify-center pt-10 lg:pt-0 z-10'><img src='/assets/model.webp' alt='Model' /></div>
      </div>
    </div>
  )
}

export default AboutWork
