import React from 'react'
import StyledXxlText from '../../../common/styledXxlText/StyledXxlText'
import StyledMdText from '../../../common/styledMdText/StyledMdText'
import StyledH6Heading from '../../../common/styledH6Heading/StyledH6Heading'
import StyledSmText from '../../../common/styledSmText/StyledSmText'
import { content } from '../../../data'
import { Link } from 'react-router-dom'

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const footer = content.layout;
  return (
    <div className='w-full h-max max-w-[1440px] bg-brown'>
      <div className='flex flex-col justify-between h-full gap-10 px-5 pt-10 pb-5 lg:flex-row lg:gap-0 lg:px-10'>
        <div className='flex flex-col justify-between lg:w-[40%]'>
          <div className='flex flex-col gap-3'>
            <div><StyledXxlText fontColor={"text-white"}>Aiman Creates</StyledXxlText></div>
            <div><StyledMdText fontColor={"text-white"}>The most versatile commercial collection, focusing on seasonal trends for fashion and lifestyle.
              The high end exclusive collection, hand-painted to push boundaries offering a fine original art.</StyledMdText></div>
          </div>
          <div className='hidden lg:flex'><StyledSmText fontColor={"text-white"}>&#169; {currentYear} Aiman Creates - Surface Pattern Textile Designer.</StyledSmText></div>
        </div>
        <div className='flex flex-col lg:w-[20%]'>
          <div className='flex flex-col gap-3'>
            <div><StyledXxlText fontColor={"text-white"}>Contact Info</StyledXxlText></div>
            <div className='flex flex-col gap-3'>
              {footer.footerInfo.map((item, index) => (
                <Link to={item.link} key={index} className='flex items-center gap-3 cursor-pointer'>
                  <div className='text-white'>{item.icon}</div>
                  <div><StyledSmText fontColor={"text-white"}>{item.title}</StyledSmText></div>
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className='flex flex-col lg:w-[20%]'>
          <div className='flex flex-col gap-3'>
            <div><StyledXxlText fontColor={"text-white"}>Newsletter</StyledXxlText></div>
            <div><StyledSmText fontColor={"text-white"}>Sign Up to get updates & news about us</StyledSmText>
              <div className='flex flex-col gap-3'>
                <div><input type='text' className='w-full py-4 pl-5 mt-1 text-xs text-white bg-dull-black placeholder:text-gray placeholder:text-xs' placeholder='Enter Email' /></div>
                <div><button disabled={true} className='w-full py-4 text-xs text-black duration-500 bg-white disabled:cursor-not-allowed hover:bg-black hover:text-white'>Subscribe</button></div>
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-row justify-center gap-3 text-white lg:flex-col'>
          {footer.headerIcons.map((item, index) => (
            <Link target='_blank' to={item.link} key={index} className='duration-500 hover:text-black'>
              {item.icon}
            </Link>
          ))}
        </div>
        <div className='flex justify-center lg:hidden'><StyledSmText fontColor={"text-white"}>&#169; {currentYear} Aiman Creates - Surface Pattern Textile Designer.</StyledSmText></div>
      </div>
      <div className='flex justify-center py-5'>
        <StyledH6Heading fontColor={"text-white font-nicholia font-thin"}>Developed by <a href='mailto:faiq.khokhar06@gmail.com' className=' hover:text-yellow hover:underline'>Faiq K.</a></StyledH6Heading>
      </div>
    </div>
  )
}

export default Footer
