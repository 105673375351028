import React from 'react'
import { content } from "../../data";
import StyledMdText from '../../common/styledMdText/StyledMdText'
import StyledLgText from '../../common/styledLgText/StyledLgText'
import StyledXxlText from '../../common/styledXxlText/StyledXxlText'
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const Testimonials = () => {

    const responsive = {
        0: { items: 1 },
        768: { items: 2 },
        1024: { items: 2 },
    };

    const renderPrevButton = ({ isDisabled }) => (
        <button
            disabled={isDisabled}
            style={{
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                top: "45%",
                left: "-3%",
                width: "30px",
                height: "30px",
                background: "#fff",
                border: "1px solid #3E3E3E99",
                borderRadius: "50%",
                color: "#000",
                fontSize: "18px",
            }
            }>
            <img
                src="/assets/backarrowlight.svg"
                alt="back-arrow"
            />
        </button>
    );

    const renderNextButton = ({ isDisabled }) => (
        <button
            disabled={isDisabled}
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: "45%",
                right: "-3%",
                width: "30px",
                height: "30px",
                background: "#fff",
                border: "1px solid #3E3E3E99",
                borderRadius: "50%",
                color: "#000",
                fontSize: "18px",
            }}
        >
            <img
                filter="hue-rotate(120deg)"
                style={{ fill: "red !important" }}
                src="/assets/nextarrowlight.svg"
                alt="next-arrow"
            />
        </button>
    );
    return (
        <div className='flex w-[90%] gap-3'>
            <AliceCarousel
                disableDotsControls={"true"}
                renderPrevButton={renderPrevButton}
                renderNextButton={renderNextButton}
                mouseTracking
                items={content.homepage.Testimonials.map((item, index) => (
                    <div key={index} className='mx-8 lg:mx-5'>
                        <div className='bg-white w-full p-[2rem] flex xl:flex-row flex-col gap-5 rounded-xl shadow-xl'>
                            <div className='xl:w-[40%] flex flex-col gap-3 items-center justify-center'>
                                <div><img src={item.imgUrl} alt={`Client Number # ${index}`} /></div>
                                <div><img src='/assets/stars.webp' alt='Clients Rating' /></div>
                            </div>
                            <div className='xl:w-[60%] flex flex-col gap-2'>
                                <div className='flex justify-between'>
                                    <div><StyledXxlText fontColor={"text-black font-bold"}>{item.clientName}</StyledXxlText></div>
                                    <div><img src='/assets/quotes.webp' alt='Quotes' /></div>
                                </div>
                                <div><StyledLgText fontColor={"text-pink"}>{item.designation}</StyledLgText></div>
                                <div><StyledMdText fontColor={"text-black font-inter"}>{item.Review}</StyledMdText></div>
                            </div>
                        </div>
                    </div>
                ))}
                responsive={responsive}
                controlsStrategy="alternate"
                autoPlay="true"
                autoPlayInterval="5000"
                autoPlayStrategy="all"
                infinite="true" />
        </div>
    )
}

export default Testimonials
