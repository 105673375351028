import React from 'react'
import StyledH2Heading from '../../../common/styledH2Heading/StyledH2Heading'
import MarqueeComponent from '../../../components/marqueeComponent/MarqueeComponent'

const ClientsSection = () => {
    return (
        <div className='h-full py-5 pb-10'>
            <div className='flex justify-center'>
                <StyledH2Heading fontColor={"text-dark-blue"}>Collaborations</StyledH2Heading>
            </div>
            <div>
                <MarqueeComponent />
            </div>
        </div>
    )
}

export default ClientsSection
