import StyledMdText from '../../../common/styledMdText/StyledMdText'
import StyledH1Heading from '../../../common/styledH1Heading/StyledH1Heading'
import StyledH2Heading from '../../../common/styledH2Heading/StyledH2Heading'
import React from 'react'
import Button from '../../../components/button/Button'

const AboutMe = ({isHome}) => {
    return (
        <div className='h-full'
            style={{
                background: `url('/assets/bg-flowers.webp')`,
                backgroundRepeat: "no-repeat"
            }}
        >
            {isHome && (<div className='flex justify-center'>
                <StyledH2Heading fontColor={"text-pink"}>About Me</StyledH2Heading>
            </div>)}
            <div className='flex flex-col-reverse lg:flex-row'>
                <div className='lg:w-[45%] lg:pt-[20%]'><img src='/assets/designer.webp' alt='Designer' /></div>
                <div className='lg:w-[55%] flex flex-col items-center'>
                    <div className='relative w-full'>
                        <div>
                            <StyledH1Heading fontColor={"text-white"}>HI</StyledH1Heading>
                        </div>
                        <div className='absolute top-[25%] left-[12%]'><StyledH2Heading fontColor={"text-dark-blue"}>I am Aiman</StyledH2Heading></div>
                    </div>
                    <div className='w-[95%] lg:w-[80%] lg:pl-20 flex flex-col gap-10 bg-yellow bg-opacity-[0.2] blur-[0.5px] lg:bg-transparent'>
                        <div><StyledMdText fontColor={"text-black"}>
                            I am a Freelance Surface Pattern Designer. I love creating prints and patterns that people could wear, style or decorate with. I’m skilled at being discrete and creative during my projects. I work in a way that suits you and create the brand style you like the best. I’ll chat to you extensively before the project, and make sure everything goes off without a hitch while you get started.
                            <br /><br />
                            I am someone in love with Rug Designs and so have experience in creating a variety of playmat and rug patterns and prints. I love creating textures more of a vintage style but I am also someone who seeks pleasure in the historical styles of art.<br /><br />I have learned a lot from my experience in arts during my studies but the times, that I would say the Golden Time of mine when I studied further from The Print School by Longina Phillips, Bear Bell Productions, and Freya Arts, helped me a lot in finding my right way to the knowledge of arts.</StyledMdText>
                        </div>
                        <div className='flex justify-center lg:justify-start'>
                            <Button link={"/contact-us"} fontColor={"text-black lg:text-pink hover:bg-pink hover:text-white duration-500"} borderColor={"border border-black hover:border-pink lg:border-pink"}>Get in Touch</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutMe
