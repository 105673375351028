import React from 'react'
import StyledH2Heading from '../../../common/styledH2Heading/StyledH2Heading';
import { content } from '../../../data';

const FashionPortfolio = () => {
    return (
        <div className='flex flex-col items-center py-10'>
            <div className='w-[90%] relative'>
                <div className='absolute hidden md:block -z-10 md:-right-[20%] top-[5%] right-10 lg:-right-[15%] lg:-top-[1%] xl:top-0'>
                    <img src='/assets/petal-down.webp' alt='Background Petal' />
                </div>
                <div className='text-center'><StyledH2Heading fontColor={"text-dark-blue"}>Fashion Portfolio</StyledH2Heading></div>
                <div className='grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3'>
                    {content.portfolio.Fashion?.slice(0, 9).map((item, index) => (
                        <div key={index} className='w-full h-full p-1 bg-white'>
                            <img
                                src={item.imageUrl}
                                alt={`Fashion Portfolio#${index + 1}`}
                                className='object-cover w-full h-full'
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default FashionPortfolio
