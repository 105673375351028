import React from "react";
import Marquee from "react-fast-marquee";
import { content } from "../../data";
import StyledXxlText from "../../common/styledXxlText/StyledXxlText";

const MarqueeLandingPage = () => {
    return (
        <div className="w-full h-full bg-yellow">
            <Marquee speed={"50"} pauseOnHover>
                {content.homepage.Landing.map((item, index) => (
                    <div key={index} className="py-5 mr-32 md:mr-40">
                        <StyledXxlText fontColor={"text-black font-semibold font-hind"}>{item.iniTitle} <span className='relative'>{item.spanText}<div className='absolute -top-1 -right-[40%] text-dark-blue hover:text-pink hover:duration-700 hover:transition-all'><svg xmlns="http://www.w3.org/2000/svg" width="100" height="50" viewBox="0 0 100 102" fill="none">
                            <path d="M49.0247 62C56.7547 42 52.3347 17.9 38.0247 2C52.6847 19.71 57.1048 43.45 65.8748 64.7C66.8748 67.08 69.6247 69.84 71.4347 68.02C59.7568 53.548 43.706 43.2474 25.6847 38.66C19.4747 37.1 12.2648 36.44 7.23475 40.4C0.234751 45.92 1.41476 56.82 4.11476 65.4C6.58476 73.21 9.8047 80.91 14.9347 87.3C20.0647 93.69 27.2947 98.69 35.4047 99.79C43.3247 100.87 51.4047 98.17 58.2047 93.99C66.7047 88.78 73.8648 80.99 76.9948 71.52C80.1248 62.05 78.8047 50.91 72.4447 43.23C72.7727 47.5945 74.223 51.8002 76.6549 55.4393C79.0868 59.0783 82.4177 62.0272 86.3247 64C86.8805 58.828 86.1595 53.5986 84.2247 48.77C86.4796 50.5953 88.3723 52.8272 89.8047 55.35C90.7065 52.1839 91.0217 48.8795 90.7347 45.6C92.1274 48.3006 94.1726 50.6106 96.6847 52.32C97.5504 49.3006 97.8887 46.1544 97.6847 43.02C97.2047 48.02 102.685 51.84 107.685 51.46C112.685 51.08 116.855 47.79 120.465 44.38C124.385 40.68 128.115 36.5 129.795 31.38C131.475 26.26 130.645 20 126.565 16.48C123.675 13.99 119.665 13.23 115.855 13.05C110.405 12.8 104.435 13.79 100.765 17.82C94.5147 24.68 98.7647 36.5 106.455 41.66C112.105 45.44 119.705 46.81 125.815 43.84C131.925 40.87 135.625 32.97 132.815 26.84C129.635 28.84 130.125 34.48 133.585 35.89C137.045 37.3 141.345 33.56 140.435 29.94L142.205 32.65C142.93 32.5496 143.625 32.2955 144.244 31.9047C144.863 31.5138 145.392 30.9952 145.794 30.3835C146.197 29.7719 146.464 29.0813 146.578 28.3581C146.692 27.6349 146.65 26.8957 146.455 26.19C146.622 26.8764 146.951 27.5132 147.412 28.0483C147.874 28.5834 148.455 29.0014 149.11 29.2681C149.764 29.5348 150.472 29.6426 151.176 29.5827C151.88 29.5227 152.56 29.2967 153.16 28.9232C153.76 28.5497 154.262 28.0393 154.627 27.4339C154.991 26.8285 155.207 26.1453 155.256 25.4405C155.305 24.7356 155.187 24.0291 154.91 23.3789C154.634 22.7287 154.207 22.1533 153.665 21.7" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M107.754 76.5104C114.195 71.8128 121.679 68.7453 129.564 67.5704" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M135.373 61.6703C133.583 62.0303 133.093 64.6703 134.243 66.0903C135.393 67.5103 137.453 67.8203 139.243 67.5903C141.277 67.3264 143.201 66.5179 144.813 65.2503C145.366 64.8779 145.831 64.3899 146.176 63.8202C146.521 63.2504 146.739 62.6124 146.813 61.9503C146.813 59.9503 144.613 58.4403 142.593 58.5903C140.573 58.7403 138.833 60.0303 137.353 61.4103" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg></div></span>
                        </StyledXxlText>
                    </div>
                ))}
            </Marquee>
        </div>
    );
};

export default MarqueeLandingPage;
