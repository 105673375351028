import React from 'react';
import { content } from '../../../data';
import StyledLgText from '../../../common/styledLgText/StyledLgText';
import { Link } from 'react-router-dom';
import MobileHeader from './MobileHeader';
import { usePath } from "../../../context/PathContext";


const Header = () => {
  const header = content.layout;
  const { path } = usePath();

  return (
    <div className='w-full max-w-[1440px] flex items-center px-5 lg:px-10 justify-between h-[110px]'>
      <div className='w-[30%] lg:w-[15%]'>
        <Link to="/">
          <img src='/assets/logo.webp' alt='Logo' /></Link></div>
      <div className='hidden gap-6 lg:flex'>
        {header.headerLinks.map((item, index) => (
          <Link to={item.link} className="uppercase" key={index}>
            <StyledLgText fontColor={`text-dark-blue hover:text-black ${path === item.link ? "text-black font-medium" : ""}`}>{item.title}</StyledLgText>
          </Link>
        ))}
      </div>
      <div className='hidden gap-4 lg:flex text-dark-blue'>
        {header.headerIcons.map((item, index) => (
          <Link target='_blank' to={item.link} key={index} className='duration-500 hover:text-black'>
            {item.icon}
          </Link>
        ))}
      </div>
      <div className='flex lg:hidden'>
        <MobileHeader />
      </div>
    </div>
  )
}

export default Header
