import { useEffect } from "react";

const GoHighLevelForm = () => {
    useEffect(() => {
        // Dynamically load the external script after the component mounts
        const script = document.createElement('script');
        script.src = "https://link.msgsndr.com/js/form_embed.js";
        script.async = true;
        document.body.appendChild(script);

        // Clean up the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className="gohighlevel-form">
            <iframe
                src="https://api.leadconnectorhq.com/widget/form/6E3jOlJoObjc8TR2BBec"
                style={{ display: "none", width: "100%", height: "100%", border: "none", borderRadius: "3px" }}
                id="popup-6E3jOlJoObjc8TR2BBec"
                data-layout="{'id':'POPUP'}"
                data-trigger-type="showAfter"
                data-trigger-value="1"
                data-activation-type="alwaysActivated"
                data-activation-value=""
                data-deactivation-type="leadCollected"
                data-deactivation-value=""
                data-form-name="Form 2"
                data-height="500"
                data-layout-iframe-id="popup-6E3jOlJoObjc8TR2BBec"
                data-form-id="6E3jOlJoObjc8TR2BBec"
                title="Form 2"
            >
            </iframe>

        </div>
    );
};

export default GoHighLevelForm;
