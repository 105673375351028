import StyledH2Heading from '../../../common/styledH2Heading/StyledH2Heading'
import React from 'react'
import { content } from '../../../data'
import StyledH5Heading from '../../../common/styledH5Heading/StyledH5Heading'
import { Link } from 'react-router-dom'
const ServicesSection = () => {
    return (
        <div className='pb-20'>
            <div className='flex justify-center py-10'>
                <StyledH2Heading fontColor={"text-dark-blue"}>Services</StyledH2Heading>
            </div>
            <div className='flex flex-col justify-around gap-10 lg:gap-0 lg:flex-row'>
                {content.homepage.Services.map((item, index) => (
                    <Link to={index === 0 ? "https://aimancreates.printsconnection.com/" : "contact-us"} target={index === 0 && "__blank"} className='flex flex-col items-center gap-5 group' key={index}>
                        <div>
                            <img className='rounded-[20rem] group-hover:scale-110 duration-500 hover:rotate-45' src={item.imgUrl} alt={item.title} />
                        </div>
                        <div className='z-10'>
                            <StyledH5Heading fontColor={"text-white group-hover:text-pink transition-colors duration-500 font-hind"}>{item.title}</StyledH5Heading>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    )
}

export default ServicesSection
